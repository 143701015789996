import axios from "axios";

export default {
    namespaced: true,
    state: {
        root: [],
        collection: {
            data: [],
            meta: {
                current_page: 1,
                last_page: null,
                per_page: 15,
                total: 0
            }
        },
        category: {
            name: null,
            parent: {

            }
        }
    },
    mutations: {},
    actions: {
        async getAll({state}, params) {
            return await axios.get("/admin/lessons-categories", {params}).then(response => {
                state.collection = response.data;
            })
        },
        async getRoot({state}) {
          return await axios.get('/admin/lessons-categories/root').then(response => {
              state.root = response.data
          })
        },
        async getOneById({state}, id) {
            return await axios.get(`/admin/lessons-categories/${id}`).then(response => {
                state.category = response.data;
                return response.data;
            })
        },
        async save({}, payload) {
            return await axios.post(`/admin/lessons-categories`, payload);
        },
        async update({}, id, payload) {
            return await axios.post(`/admin/lessons-categories/${id}`, payload);
        }

    },
    getters: {}
}