<template>
  <main>
    <loader :loading="loading"/>
    <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 260px;">
      <a href="/" class="d-flex align-items-center mb-3 me-md-auto text-white text-decoration-none mt-3">
        <img src="@/assets/logo-white.svg" class="sidebar-logo ms-3"/>
      </a>
      <hr>
      <ul class="nav nav-pills flex-column mb-auto menu-item">
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name === 'dashboard'}"
              @click.prevent="$router.push({name: 'dashboard'})"
          >
            <i class="la la-dashboard"/>
            Dashboard
          </a>
        </li>
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name?.includes('clients')}"
              @click.prevent="$router.push({name: 'clients'})"
          >
            <i class="la la-users"/>
            Clientes
          </a>
        </li>
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name === 'portals'}"
              @click.prevent="$router.push({name: 'portals'})"
          >
            <i class="la la-project-diagram"/>
            Portais
          </a>
        </li>
        <li
            data-bs-toggle="collapse"
            href="#lessons"
            aria-expanded="false"
            aria-controls="lessons"
        >
          <a href="#" class="nav-link text-white">
            <i class="la la-graduation-cap"/>
            Treinamentos
          </a>
        </li>

        <div class="collapse ms-4" id="lessons" :class="{'show': $route.name?.includes('lessons')}">
          <li>
            <a
                href="#"
                class="nav-link text-white"
                :class="{'active' : $route.name === 'lessons'}"
                @click.prevent="$router.push({name: 'lessons'})"
            >
              Treinamentos
            </a>
          </li>
          <li>
            <a
                href="#"
                class="nav-link text-white"
                :class="{'active' : $route.name?.includes('lessons-categories')}"
                @click.prevent="$router.push({name: 'lessons-categories'})"
            >
              Categorias
            </a>
          </li>
        </div>
      </ul>
      <hr>
      <div class="dropdown">
        <a
            href="#"
            class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser"
            data-bs-toggle="dropdown">
          <i class="la la-user la-2x" />
          <strong class="mt-1 ms-2">{{ $store.getters["auth/loggedInUser"].name }}</strong>
        </a>
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
          <li><a class="dropdown-item" href="#" @click="clearFields" data-bs-toggle="modal" data-bs-target="#password-modal">Alterar senha</a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" href="#" @click.prevent="logout">Sair</a></li>
        </ul>
      </div>
    </div>

    <div class="main-content">
      <div class="container-lg">
        <slot></slot>
      </div>
    </div>

    <modal id="password-modal" :custom-classes="['modal-dialog-centered', 'modal-sm']" title="Alterar Senha">
      <template #body>
        <div class="row g-2">
          <div class="col-12">
            <label class="form-label">Senha atual</label>
            <input
                class="form-control"
                type="password"
                v-model="$v.payload.actual.$model"
                :class="{ 'is-invalid' : $v.payload.actual.$error }"
            />
            <div class="invalid-feedback" v-if="!$v.payload.actual.required">Campo obrigatório</div>
            <div class="invalid-feedback" v-if="!$v.payload.actual.minLength">8 ou mais caracteres</div>
          </div>

          <div class="col-12">
            <label class="form-label">Senha nova</label>
            <input
                class="form-control"
                type="password"
                v-model="$v.payload.password.$model"
                :class="{ 'is-invalid' : $v.payload.password.$error }"
            />
            <div class="invalid-feedback" v-if="!$v.payload.password.required">Campo obrigatório</div>
            <div class="invalid-feedback" v-if="!$v.payload.password.minLength">8 ou mais caracteres</div>
          </div>

          <div class="col-12">
            <label class="form-label">Confirme a senha</label>
            <input
                class="form-control"
                type="password"
                v-model="$v.payload.password_confirmation.$model"
                :class="{ 'is-invalid' : $v.payload.password_confirmation.$error }"
            />
            <div class="invalid-feedback" v-if="!$v.payload.password_confirmation.required">Campo obrigatório</div>
            <div class="invalid-feedback" v-if="!$v.payload.password_confirmation.minLength">8 ou mais caracteres</div>
            <div class="invalid-feedback" v-if="!$v.payload.password_confirmation.confirm">Não confere com a nova senha</div>
          </div>

        </div>
      </template>

      <template #footer>
        <button type="button" class="btn btn-primary" @click.prevent="changePassword">Alterar</button>
      </template>
    </modal>

  </main>
</template>
<script>
import {mapActions} from "vuex";
import Modal from "@/components/shared/Modal.vue";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {Modal as bsModal} from "bootstrap";
import axios from "axios";
import Loader from "@/components/shared/loader.vue";

export default {
  name: 'default-layout',
  components: {Loader, Modal},
  props: {},
  data() {
    return {
      passwordModal: null,
      loading: false,
      payload: {
        password: null,
        actual: null,
        password_confirmation: null
      }
    }
  },
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(8)
      },
      actual: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        confirm: sameAs('password')
      },
    }
  },
  mounted() {
    this.passwordModal = bsModal.getOrCreateInstance(document.getElementById('password-modal'));
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async changePassword() {
      this.$v.$touch();
      if(this.$v.payload.$invalid) {
        return false;
      }

      this.loading = true;
      await axios.post('/admin/auth/change-password', this.payload).then((response) => {
        this.passwordModal.hide();
        this.clearFields();
      }).catch(error => {
        let errorMessage = error.response.message;

        if(error.response.status === 422) {
          errorMessage = '';
          const errors = Object.values(error.response.data.errors);
          errors.forEach(item => {
            item.forEach(e => {
              errorMessage += e + '\n';
            })
          });
        }
        this.$swal({
          icon: 'error',
          text: errorMessage
        })
      }).finally(() => {
        this.loading = false;
      })
    },
    clearFields() {
      this.payload =  {
        password: null,
        actual: null,
        password_confirmation: null
      };
      this.$v.$reset();
    }
  }
}
</script>
<style lang="scss" scoped>

.dropdown-toggle {
  outline: 0;
}

.menu-item li i.la {
  font-size: 1.5rem;
  vertical-align: -.100em;
  pointer-events: none;
  cursor: pointer;
  margin-right: 5px;
}

.sidebar-logo {
  width: 150px;
}

.main-content {
  padding: 1.8rem 1.5rem;
  width: 100%;
  overflow-x: auto;
}
</style>
