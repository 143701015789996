<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  }
}
</script>
<style>
body {
  min-height: 100vh;
  background: #f5f4f3 !important;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
