import axios from "axios";

export default {
    namespaced: true,
    state: {
        collection: {
            data: [],
            meta: {
                current_page: 1,
                last_page: null,
                per_page: 15,
                total: 0
            }
        },
        client: {
            person: {
                complement: {}
            },
            portals: [],
            import: []
        }
    },
    mutations: {},
    actions: {
        async getAll({state}, params) {
            return await axios.get("/admin/company", {params}).then(response => {
                state.collection = response.data;
            })
        },
        async getOneById({state}, id) {
            return await axios.get(`/admin/company/${id}`).then(response => {
                state.client = response.data;
                return response.data;
            })
        },
        async save({}, payload) {
            return await axios.post(`/admin/company`, payload);
        }

    },
    getters: {}
}