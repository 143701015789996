<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'blank-layout',
  props: {},
  data() {
    return {}
  }
}
</script>