import axios from "axios";

export default {
    namespaced: true,
    state: {
        collection: {
            data: [],
            meta: {
                current_page: 1,
                last_page: null,
                per_page: 15,
                total: 0
            }
        },
        spreaders: []
    },
    mutations: {},
    actions: {
        async getAll({state}, params) {
            return await axios.get("/admin/portal", {params}).then(response => {
                state.collection = response.data;
            })
        },
        async getSpreaders({state}) {
            return await axios.get("/admin/portal/spreaders").then(response => {
                state.spreaders = response.data
            })
        },
        async save({}, payload) {
            return await axios.post(`/admin/portal`, payload);
        },
        async update({}, id, payload) {
            return await axios.post(`/admin/portal/${id}`, payload);
        }
    },
    getters: {}
}