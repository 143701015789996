import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/clientes',
    name: 'clients',
    component: () => import('../views/Clients')
  },
  {
    path: '/clientes/add',
    name: 'clients-new',
    component: () => import('../views/Clients/add.vue')
  },
  {
    path: '/clientes/edit/:id',
    name: 'clients-edit',
    component: () => import('../views/Clients/add.vue')
  },
  {
    path: '/clientes/:id',
    name: 'clients-details',
    component: () => import('../views/Clients/details.vue')
  },
  {
    path: '/portais',
    name: 'portals',
    component: () => import('../views/Portals.vue')
  },
  {
    path: '/treinamentos',
    name: 'lessons',
    component: () => import('../views/Lessons')
  },
  {
    path: '/categorias',
    name: 'lessons-categories',
    component: () => import('../views/LessonsCategories')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      layout: 'blank'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  if(to.name === 'login' && isAuthenticated) {
    next({name: 'dashboard'})
  }

  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})

export default router
