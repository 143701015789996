import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {dateFilter} from "vue-date-fns";
import Vuelidate from 'vuelidate'
import {serialize} from "object-to-formdata";
import VueSweetalert2 from 'vue-sweetalert2';

import Blank from "@/layouts/Blank.vue";
import Default from "@/layouts/Default.vue";
import axiosSetup from "./axios";

import '@/assets/sass/app.scss'
import "bootstrap"
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.component('blank-layout', Blank)
Vue.component('default-layout', Default)
Vue.filter("date", dateFilter);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false
axiosSetup();

Vue.prototype.$serialize = function (object) {
    return serialize(object, {
        indices: true,
        booleansAsIntegers: true,
        allowEmptyArrays: false,
    })
};

Vue.prototype.$scrollTop = function() {
    const container = document.querySelector('.main-content');
    container.scrollTo(0,0);
}

const urlUtils = {
    replaceQueryString(queryParams) {
        const queryString = new URLSearchParams(queryParams);
        const location = window.location;

        const url = `${location.protocol}//${location.host}${location.pathname}?${queryString}`;
        window.history.pushState({path:url},'',url);
    },

    assignQueryString(route, params) {
        Object.entries(params).forEach(param => {
            if(Array.isArray(param[1])) {
                if(!param[1].length) {
                    return false
                }
            }
            if(param[1]) {
                route.query[param[0]] = param[1].toString()
            }
        });

        this.replaceQueryString(route.query);
    }
}

Vue.prototype.$urlUtils = urlUtils;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
