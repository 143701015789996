<template>
  <div class="d-flex justify-content-center align-items-center flex-column loading-page" v-if="loading">
    <div class="spinner-border text-primary" role="status">
    </div>
    <span class="loading-text">Carregando...</span>
  </div>
</template>

<script>
export default {
  props: {
    loading: false
  }
}
</script>

<style scoped lang="scss">
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999999999;

  .loading-text {
    color: #666;
    margin-top: 15px;
  }
}
</style>
