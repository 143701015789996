import axios from "axios";

export default {
    namespaced: true,
    state: {
        collection: {
            data: [],
            meta: {
                current_page: 1,
                last_page: null,
                per_page: 15,
                total: 0
            }
        },
        lesson: {
            categoryObject: {
                parent: {}
            },
        }
    },
    mutations: {},
    actions: {
        async getAll({state}, params) {
            return await axios.get("/admin/lessons", {params}).then(response => {
                state.collection = response.data;
            })
        },
        async getOneById({state}, id) {
            return await axios.get(`/admin/lessons/${id}`).then(response => {
                state.lesson = response.data;
                return response.data;
            })
        },
        async save({}, payload) {
            return await axios.post(`/admin/lessons`, payload);
        }

    },
    getters: {}
}