import axios from "axios";

export default {
    namespaced: true,

    state: {
        states: [
            {
                item: '1c1c4ea7-380b-4ed3-87e8-f50760735c19',
                uf: 'AC',
                name: 'Acre',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'c0608390-fde0-41b2-b178-510175fa1bda',
                uf: 'AL',
                name: 'Alagoas',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'c7da475a-5ff2-4f4c-af0b-eb1486f3b13d',
                uf: 'AM',
                name: 'Amazonas',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '98b31968-73ee-4312-8657-558af44a3081',
                uf: 'AP',
                name: 'Amapá',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'f5a52bc7-0df1-40f5-a2d6-55e9a03e6668',
                uf: 'BA',
                name: 'Bahia',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'a2aa199b-fc87-4fea-9a76-5ed47aa871f2',
                uf: 'CE',
                name: 'Ceará',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '12aa3513-0d65-4711-970f-fbdf2f371ec3',
                uf: 'DF',
                name: 'Distrito Federal',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '242ba0a6-29d6-4aea-829d-3e64ebf846de',
                uf: 'ES',
                name: 'Espírito Santo',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '29ac2dee-8fdb-47a6-a90f-c096b5f36b9d',
                uf: 'GO',
                name: 'Goiás',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '81d313cc-f617-420b-8951-bc28e3ef5a73',
                uf: 'MA',
                name: 'Maranhão',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '0fc81dbf-fa12-4acc-b578-fad9cc1607ab',
                uf: 'MG',
                name: 'Minas Gerais',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '95bfa076-fec6-42af-8fd1-168abfc6400b',
                uf: 'MS',
                name: 'Mato Grosso do Sul',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '270c1ebd-82fa-42ca-9694-305236e370b0',
                uf: 'MT',
                name: 'Mato Grosso',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '00cabd28-39e2-4f44-8edb-b3cc13062ad9',
                uf: 'PA',
                name: 'Pará',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '5a2e9aa5-1fb5-4a25-960a-1319f72a91f1',
                uf: 'PB',
                name: 'Paraíba',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '81ae760c-d13f-44f3-8bbf-ca43b2308732',
                uf: 'PE',
                name: 'Pernambuco',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'a5b8f170-a7b7-414f-8e91-8891b550357b',
                uf: 'PI',
                name: 'Piauí',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'c8e3790f-570a-4c2b-9541-103cb544a9bc',
                uf: 'PR',
                name: 'Paraná',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '902a354b-6e89-49a7-8528-57e6c0239d47',
                uf: 'RJ',
                name: 'Rio de Janeiro',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'be238a9f-aebc-4edb-a2c5-94050aabaf34',
                uf: 'RN',
                name: 'Rio Grande do Norte',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '58fafd74-0e59-4264-96b3-dc4d3ac21e57',
                uf: 'RO',
                name: 'Rondônia',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'e3f0b33f-8a94-4126-b727-11b3f0eb497a',
                uf: 'RR',
                name: 'Roraima',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: 'a6c4c0e8-8d66-4c52-9b95-5c7ba8b33c98',
                uf: 'RS',
                name: 'Rio Grande do Sul',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '7bce8c5c-d1b9-4c77-b88f-da9428ebc0bb',
                uf: 'SC',
                name: 'Santa Catarina',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '10bd373e-8037-4323-aee2-702b4807b880',
                uf: 'SE',
                name: 'Sergipe',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '54b954a9-2141-47ef-9109-d9d628d64dd8',
                uf: 'SP',
                name: 'São Paulo',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
            {
                item: '0e8fae86-d100-4f1c-bf00-3686f6f4587d',
                uf: 'TO',
                name: 'Tocantins',
                country: 'd258262c-4cd3-444f-8d57-e43930dc45f0'
            },
        ],
    },
    actions: {
        async getAddressByCep({state}, cep) {
            if (!cep || cep === '' || cep.length < 9) {
                return;
            }

            return await axios.get(`https://buscacep.sub100.com.br/api/v1?formato=json&cep=${cep}`).then(response => {
                if (response.data.resultado) {
                    return {
                        neighborhood: response.data.bairro,
                        address: response.data.endereco,
                        city: response.data.cidade,
                        uf: state.states.find(item => item.uf === response.data.uf).item
                    }
                }
            });
        },
    }
}